import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShepherdService } from "angular-shepherd";
import { UserGuideStep } from "../models/user-guide-step.model";
import Step from "shepherd.js/src/types/step";
import { UserGuideEnum } from "../enums/user-guide.enum";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageKeys } from "../enums/session-storage-keys.enum";
import { Util } from "./util.service";
import { environment } from "src/environments/environment";
import { TipoDispositivoService } from "./tipo-dispositivo.service";

@Injectable()
export class UserGuideService {
  private steps: Step.StepOptions[] = [];

  constructor(
    private shepherdService: ShepherdService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private tipoDispositivoService: TipoDispositivoService
  ) {}

  isVisible() {
    const el = document.getElementsByClassName("sme-tour-theme");
    return el?.length > 0;
  }

  private createButton(
    type: "VOLTAR" | "PROXIMO" | "FINALIZAR" | "PULAR",
    backButtonText?: string,
    nextButtonText?: string,
    stopAfterHide?: boolean,
    nextFunction?: () => void
  ): Step.StepOptionsButton {
    if (type === "PROXIMO") {
      return {
        action() {
          nextFunction?.();
          if (stopAfterHide) {
            this.complete();
          } else {
            this.next();
          }
        },
        text: this.translateService.instant(nextButtonText || "paginacao.proximo"),
      };
    }
    if (type === "VOLTAR") {
      return {
        action() {
          this.back();
        },
        classes: "shepherd-button-secondary",
        text: this.translateService.instant(backButtonText || "paginacao.anterior"),
      };
    }
    if (type === "FINALIZAR") {
      return {
        action() {
          nextFunction?.();
          this.complete();
        },
        text: this.translateService.instant(nextButtonText || "botaoConcluir"),
      };
    }
    if (type === "PULAR") {
      return {
        action() {
          this.cancel();
        },
        classes: "shepherd-button-secondary",
        text: this.translateService.instant(backButtonText || "tutorial.pular"),
      };
    }
  }

  private getStepButtons(index: number, totalItems: number, step: UserGuideStep): Step.StepOptionsButton[] {
    const isFirst = index === 0;
    const isLast = index === totalItems - 1;
    const buttons = [];
    if (isFirst) {
      buttons.push(
        this.createButton("PULAR", step.backButtonText, step.nextButtonText),
        this.createButton(
          "PROXIMO",
          step.backButtonText,
          step.nextButtonText,
          step.stopAfterHide,
          step.whenHide?.bind(step)
        )
      );
    } else if (isLast) {
      buttons.push(
        this.createButton("VOLTAR", step.backButtonText, step.nextButtonText),
        this.createButton(
          "FINALIZAR",
          step.backButtonText,
          step.nextButtonText,
          step.stopAfterHide,
          step.whenHide?.bind(step)
        )
      );
    } else {
      buttons.push(
        this.createButton("VOLTAR", step.backButtonText, step.nextButtonText),
        this.createButton(
          "PROXIMO",
          step.backButtonText,
          step.nextButtonText,
          step.stopAfterHide,
          step.whenHide?.bind(step)
        )
      );
    }
    return buttons;
  }

  private parseShepherdStep(step: UserGuideStep, elementIndex: number, totalItems: number) {
    const shepherdStep: Step.StepOptions | any = {
      title: this.translateService.instant(step.title),
      text: step.translateText === false ? step.text : this.translateService.instant(step.text),
      classes: step.customClass,
      attachTo: {
        element: step.element,
        on: this.tipoDispositivoService.isDesktop() ? step.position || "top" : "top",
      },
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "Cancelar",
      },
      when: {
        show: function () {
          setTimeout(() => {
            this.getElement().scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          });
        },
      },
      showOn: step.showOn,
      beforeShowPromise: step.beforeShowPromise,
      buttons: this.getStepButtons(elementIndex, totalItems, step),
      next: this.shepherdService.next.bind(this.shepherdService),
    };
    return shepherdStep;
  }

  setSteps(steps: UserGuideStep[]) {
    this.steps = steps.map((step, index) => this.parseShepherdStep(step, index, steps.length));
  }

  private getTutoriaisFromStorage() {
    return this.localStorageService.getItem(SessionStorageKeys.TUTORIAIS) || {};
  }

  private saveTutorialOnStorage(item: UserGuideEnum) {
    const tutoriaisSalvos = this.getTutoriaisFromStorage();
    tutoriaisSalvos[item] = {
      max_time: Util.addDiasInData(new Date(), 30).getTime(),
    };
    this.localStorageService.setItem(SessionStorageKeys.TUTORIAIS, tutoriaisSalvos);
  }

  private isTutorialExpirado(item: UserGuideEnum) {
    const tutorial = this.getTutoriaisFromStorage()[item];
    const isExpirado = tutorial && tutorial.max_time < new Date().getTime();
    if (!tutorial || isExpirado) {
      this.saveTutorialOnStorage(item);
      return true;
    }
    return false;
  }

  private configBeforeStart() {
    this.shepherdService.defaultStepOptions = {
      classes: "sme-tour-theme",
    };
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.addSteps(this.steps);
  }

  start(item: UserGuideEnum) {
    setTimeout(() => {
      if (environment.exibirTutorialDinamico && this.isTutorialExpirado(item)) {
        this.configBeforeStart();
        this.shepherdService.start();
      }
    });
  }

  repeat() {
    this.configBeforeStart();
    this.shepherdService.start();
  }
}
