<div class="container" (click)="onClickCard(testePendenteAcaoDefinida)">
  <div class="cabecalho">
    <div style="display: flex; flex-direction: column; width: 50%">
      <span class="nome-usuario">{{ testePendenteAcaoDefinida.dsUsuario }}</span>
      <span class="cargo">{{ testePendenteAcaoDefinida.dsCargo }}</span>
      @if (showIdAcao) {
        <span class="id-acao"> ID: {{ testePendenteAcaoDefinida.idTesteExecutado }}</span>
      }
    </div>
    <div style="display: flex; flex-direction: row; width: 50%; justify-content: flex-end">
      @if (testePendenteAcaoDefinida.flIntrajornada) {
        <img
          height="25"
          [pTooltip]="getHint('hintIntrajornada')"
          tooltipPosition="left"
          src="/assets/images/icone-fadiga.svg"
          alt="Ícone fadiga"
          />
      }
      @if (testePendenteAcaoDefinida.flEmFormacaoBancoDados) {
        <em
          [pTooltip]="getHint('hintBancoVazio')"
          tooltipPosition="left"
          class="material-icons"
          >hourglass_empty</em
          >
        }
        @if (!testePendenteAcaoDefinida.flEmFormacaoBancoDados) {
          <em
            [pTooltip]="getHint('hintBancoCheio')"
            tooltipPosition="left"
            class="material-icons"
            >hourglass_full</em
            >
          }
        </div>
      </div>
      <span class="historico" translate>dashboard.historico</span>
      <div class="progress-bar-container">
        <div class="progress-bar" [style.width.%]="testePendenteAcaoDefinida.vlHistNormal">
          <div class="container-percentual-e-indicador">
            <div
          [ngClass]="{
            'container-percentual-menor-90': testePendenteAcaoDefinida.vlHistNormal < 90,
            'container-percentual-maior-90': testePendenteAcaoDefinida.vlHistNormal >= 90
          }"
              >
              <div class="evoluiu"></div>
              <span class="valor-percentual">{{ testePendenteAcaoDefinida.vlHistNormal }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div *appIfProduct>
        <div style="margin-top: 43px">
          <span class="parametro" translate>dashboard.parametro</span>
        </div>
        <div style="margin-top: 5px; margin-bottom: 10px">
          <label>{{ testePendenteAcaoDefinida.dsClassificacaoResultado }}</label>
        </div>
      </div>
      <div style="margin-top: 16px">
        <div>
          <span class="acao-sugerida" translate>dashboard.acaoSugerida</span>
        </div>
        <div
          class="texto-acao-sugerida"
          [pTooltip]="testePendenteAcaoDefinida.dsAcaoSugerida"
          tooltipPosition="bottom"
          >
          <label>{{ testePendenteAcaoDefinida.dsAcaoSugerida }}</label>
        </div>
      </div>
      @if (flExibirFadiga && testePendenteAcaoDefinida?.cdScoreFadiga) {
        <div style="margin-top: 16px">
          <div>
            <span class="acao-sugerida" translate>cardAcaoNaoDefinida.scoreFadiga</span>
          </div>
          <div class="texto-acao-sugerida">
            <label translate> scoreFadigaEnum.{{ testePendenteAcaoDefinida.cdScoreFadiga }}</label>
          </div>
        </div>
      }
    </div>
