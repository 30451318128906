<form #form="ngForm">
  <div class="container-item-linha p-mb-4 p-mt-3" style="width: 100%">
    <span class="label acaoSugerida" translate>dashboard.acaoSugerida</span>
    <div>{{ detalheAcaoADefinir.dsAcaoSugerida }}</div>
  </div>
  <div>
    <div id="definir-acao" class="container-item-linha" style="width: 100%">
      <span class="label definirAcao" translate>dashboard.definirAcao</span>
      <p-dropdown
        [options]="acoesDefinidas"
        [(ngModel)]="acaoDefinidaTeste"
        [placeholder]="'dashboard.nenhumaAcaoDefinida' | translate"
        name="acaoDefinidaTeste"
        [disabled]="isAcaoPreDefinida"
        required
        [style]="{ width: '100%' }"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span style="vertical-align: middle">{{ item.label }}</span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="ui-helper-clearfix">
            <div>{{ item.label }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div style="margin-top: 40px">
    <span class="ui-float-label" style="width: 100%">
      <label class="label" for="float-input">
        <span translate> dashboard.comentario </span>
        @if (comentarioObrigatorio) {
        <span> * </span>
        }
      </label>
      @if (isAcaoPreDefinida && comentarioPreDefinido) {
      <p class="p-mt-1">
        <span class="label p-d-block">
          <span translate> dashboard.escritoPor </span>
          {{ detalheAcaoADefinir?.preDefinicaoAcao?.dsUsuario }}:
        </span>
        <span> {{ comentarioPreDefinido }} </span>
      </p>
      }
      <textarea
        id="float-input"
        class="comentario"
        autoResize="false"
        maxlength="2000"
        #inputTextCount
        [required]="comentarioObrigatorio"
        pInputTextarea
        name="comentario"
        [(ngModel)]="comentario"
      ></textarea>
      <div>
        <label class="label-input" translate>qtdRestanteCaracter</label>:
        {{ 2000 - inputTextCount.value.length }}
      </div>
    </span>
  </div>
  <div class="center container-buttons" style="margin-top: 40px">
    @if (exibirEntrevistaDirecionada) {
    <button
      id="btn-entrevista-direcionada"
      pButton
      label="Entrevista Direcionada"
      type="button"
      class="botao-branco botao-entrevista-direcionada"
      (click)="showPopupEntrevista()"
    ></button>
    }
    <button
      [disabled]="!form.valid"
      pButton
      (click)="definirAcao()"
      type="button"
      [label]="'botaoSalvar' | translate"
      pButton
      class="botao"
      id="salvar-teste"
      [loading]="loadingButtonSalvar"
    ></button>
  </div>
</form>

@if (exibirEntrevistaDirecionada) {
<app-popup-entrevista-direcionada #popupEntrevistaDirecionada (confirmar)="setRespostas($event)">
</app-popup-entrevista-direcionada>
}
