<!-- Dialogo de definição de ações em lote -->
<p-dialog
  [modal]="true"
  [style]="{ width: '765px', height: '540px' }"
  blockScroll="true"
  [showHeader]="false"
  [(visible)]="showDialogDefinirAcaoEmLote"
  (onHide)="esconderPopup()"
>
  <div class="container-definir-acao-lote">
    <div class="titulo" translate>dashboard.tituloDefiniarAcao</div>
    <div class="linha">
      <div class="circulo"><span style="padding-top: 2px">1</span></div>
      <div class="texto-linha"><span translate>dashboard.textoPasso1AcaoPendente</span></div>
    </div>
    <div class="linha-pontilhada">
      <div class="p-col-12" style="padding-left: 20px">
        <div
          id="idContainerHierarquiaDefinirAcao"
          class="container-hierarquia"
          (click)="abrirHierarquiasDefinirAcao(opHierarquiasDefinirAcao, $event)"
        >
          @if (!hierarquiaSelecionadaDefinirAcao?.data?.dsHierarquia) {
          <span>
            <label id="labelHierarquia" translate>dashboard.nenhumaHierarquiaSelecionada</label>
          </span>
          }
          <span class="valor-hierarquia-selecionada" style="color: #38ac46; font-size: 14px">{{
            hierarquiaSelecionadaDefinirAcao?.data?.dsHierarquia
          }}</span>
          <div
            id="iconeHierarquia"
            style="right: 13px"
            class="ui-dropdown-trigger ui-state-default ui-corner-right icone-dropdown-hierarquia"
          >
            <span
              class="ui-dropdown-trigger-icon ui-clickable pi pi-chevron-down pi pi-chevron-down"
              style="margin-top: 10px"
            ></span>
          </div>
        </div>
      </div>
      <div class="p-col-12" style="margin-left: 15px">
        <div class="container-filhas-hierarquias">
          <label class="label-container-hierarquia" style="margin-top: 3px; margin-left: -4px">
            <p-checkbox
              name="groupname"
              [label]="'dashboard.filtroIncluirFilhas' | translate"
              [binary]="true"
              name="requestDefinirAcaoLoteTO.flIncluirFilhas"
              [(ngModel)]="requestDefinirAcaoLoteTO.flIncluirFilhas"
            ></p-checkbox>
          </label>
        </div>
      </div>
    </div>
    <div class="linha">
      <div class="circulo"><span style="padding-top: 2px">2</span></div>
      <div class="texto-linha">
        <span translate>dashboard.textoPasso2AcaoPendente</span><span>&nbsp;*</span>
      </div>
    </div>
    <div class="linha-pontilhada">
      <div class="p-col-12 container-acao">
        <app-dropdown
          [options]="listaAcoesPendentes"
          (onChange)="selecionarAcaoSugerida($event.value)"
          [(ngModel)]="idAcaoSugeridaSelecionado"
          required
        >
        </app-dropdown>
      </div>
    </div>
    <div class="linha">
      <div class="circulo"><span style="padding-top: 2px">3</span></div>
      <div class="texto-linha">
        <span translate>dashboard.textoPasso3AcaoPendente</span><span>&nbsp;*</span>
      </div>
    </div>
    <div class="p-col-12 container-acao" style="padding-left: 32px">
      <app-dropdown [options]="listaAcoes" (onChange)="selecionarAcaoDefinida($event.value)" required>
      </app-dropdown>
    </div>
  </div>
  <div class="p-grid container-buttons">
    <div class="p-col-6">
      <button type="button" class="botao-cancelar" (click)="esconderPopup()">
        <span translate>dashboard.cancelar</span>
      </button>
    </div>
    <div class="p-col-6">
      <button
        type="button"
        pButton
        [disabled]="
          recebeLoadButton ||
          requestDefinirAcaoLoteTO.idAcaoDefinida == null ||
          requestDefinirAcaoLoteTO.idAcaoSugerida == null
        "
        class="botao-confirmar"
        (click)="definirAcaoEmLote()"
        [loading]="recebeLoadButton"
      >
        <span translate>dashboard.confirmar</span>
      </button>
    </div>
  </div>
</p-dialog>

<p-overlayPanel #opHierarquiasDefinirAcao>
  <p-tree
    [style]="{ width: '100%' }"
    id="hierarquiaDefinirAcao"
    [value]="hierarquias"
    [filter]="true"
    selectionMode="single"
    [(selection)]="hierarquiaSelecionadaDefinirAcao"
    (onNodeSelect)="onNodeSelectDefinirAcao($event, opHierarquiasDefinirAcao)"
  >
  </p-tree>
</p-overlayPanel>
