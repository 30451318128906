<div class="container-detalhe">
  @if (!isLoading) {
    <div id="infos-usuario" class="container">
      <div class="p-grid" style="margin-top: 35px">
        <div class="p-md-4 p-col-12">
          <span class="label" translate>dashboard.nomeCompleto</span>
          <span class="valor">{{ detalheAcaoADefinir.dsUsuario }}</span>
        </div>
        <div class="p-md-4 p-col-12">
          <span class="label" translate>dashboard.matricula</span>
          <span class="valor">{{ detalheAcaoADefinir.cdMatricula }}</span>
        </div>
        <div class="p-md-4 p-col-12">
          <span class="label" translate>dashboard.dataDeExecucao</span>
          <span class="valor">{{
            detalheAcaoADefinir.dtExecucao.toLocaleString(translateService.currentLang.replace("_", "-"))
          }}</span>
        </div>
        <div class="p-md-4 p-col-12">
          <span class="label" translate>dashboard.cargo</span>
          <span class="valor">{{ detalheAcaoADefinir.dsCargo }}</span>
        </div>
        <div class="p-md-4 p-col-12">
          <span class="label" translate>dashboard.hierarquia</span>
          <span class="valor">{{ detalheAcaoADefinir.dsHierarquia }}</span>
        </div>
        @if (exibeId) {
          <div class="p-md-4 p-col-12">
            <span class="label" translate>ID</span>
            <span class="valor">{{ detalheAcaoADefinir.idTesteExecutado }}</span>
          </div>
        }
      </div>
      <div *appIfProduct class="linha" style="margin-top: 40px; flex-wrap: wrap; margin-right: -90px">
        <span class="label historioDesvioParametro" translate>dashboard.historioDesvioParametro</span>
        <div class="container-classificacao">
          @for (item of detalheAcaoADefinir.histClassifFinal; track item) {
            <div
              class="classificacao"
              [style.border-right]="item.border"
              [pTooltip]="item.dsGrupoExplicacao"
              tooltipPosition="top"
              >
              <span class="valor-classificao">{{ item.vlPerc }}%</span>
              <span class="label-classificao">{{ item.dsGrupo }}</span>
            </div>
          }
        </div>
      </div>
      @if (detalheAcaoADefinir?.histNivelResultado?.length) {
        <div class="linha" style="margin-top: 20px">
          <div id="containeGrafico" class="card" style="width: 100%; display: flex; position: relative">
            <app-grafico-intrajornada [detalheAcaoADefinir]="detalheAcaoADefinir"></app-grafico-intrajornada>
          </div>
        </div>
      }
      <app-grafico-indicador-fadiga
        [idUsuario]="detalheAcaoADefinir?.idUsuario"
        [username]="detalheAcaoADefinir?.dsUsuario"
        />
        <div>
          <p-accordion>
            <p-accordionTab [header]="'dashboard.historicoDeacoes' | translate">
              <p-table styleClass="p-datatable-striped" [value]="this.historicoAcoes" id="histocioAcoesTable">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 108px" translate scope="col">dashboard.data</th>
                    <th translate scope="col">dashboard.acaoSugerida</th>
                    <th translate scope="col">dashboard.acaoDefinida</th>
                    <th translate scope="col">dashboard.dtAcaoDefinida</th>
                    <th translate scope="col">dashboard.dsJustificativaAcao</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-historico>
                  <tr>
                    <td style="width: 108px">
                      {{ historico.dtExecucao.toLocaleDateString() }}
                      {{ historico.dtExecucao.toLocaleTimeString() }}
                    </td>
                    <td>{{ historico.dsAcaoSugerida }}</td>
                    <td>{{ historico.dsAcaoDefinida }}</td>
                    <td>
                      {{ historico.dtAcaoDefinida ? historico.dtAcaoDefinida.toLocaleDateString() : "-" }}
                      {{ historico.dtAcaoDefinida ? historico.dtAcaoDefinida.toLocaleTimeString() : "" }}
                    </td>
                    <td>
                      @if (historico.dsJustificativaAcao) {
                        <button
                          pButton
                          class="ver-comentario-completo-btn"
                          (click)="exibirComentarioCompleto(historico.dsJustificativaAcao)"
                          >
                          <em class="material-icons"> visibility </em>
                        </button>
                      }
                      {{ historico.dsJustificativaAcao }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </p-accordionTab>
          </p-accordion>
        </div>
        @if (detalheAcaoADefinir?.histAcaoDefinida?.length) {
          <div class="linha">
            <p-accordion [ngStyle]="{ width: '100%' }">
              <p-accordionTab [header]="'dashboard.historicoAlteracoesAcaoDefinida' | translate">
                <p-table styleClass="p-datatable-striped" [value]="detalheAcaoADefinir?.histAcaoDefinida">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 108px" translate scope="col">dashboard.dtAcaoDefinidaAnt</th>
                      <th translate scope="col">dashboard.dtAcaoDefinidaNova</th>
                      <th translate scope="col">dashboard.dsAcaoDefinidaAnt</th>
                      <th translate scope="col">dashboard.dsAcaoDefinidaNova</th>
                      <th translate scope="col">dashboard.cdMatriculaUsuarioNova</th>
                      <th translate scope="col">dashboard.dsJustificativaAcaoNova</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-historico>
                    <tr>
                      <td>{{ historico.dtAcaoDefinidaAnt | date : "short" }}</td>
                      <td>{{ historico.dtAcaoDefinidaNova | date : "short" }}</td>
                      <td>{{ historico.dsAcaoDefinidaAnt }}</td>
                      <td>{{ historico.dsAcaoDefinidaNova }}</td>
                      <td>{{ historico.cdMatriculaUsuarioNova }}</td>
                      <td>
                        @if (historico.dsJustificativaAcaoNova) {
                          <button
                            pButton
                            class="ver-comentario-completo-btn"
                            (click)="exibirComentarioCompleto(historico.dsJustificativaAcaoNova)"
                            >
                            <em class="material-icons"> visibility </em>
                          </button>
                        }
                        {{ historico.dsJustificativaAcaoNova }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
            </p-accordion>
          </div>
        }
        <div class="container-icone-voltar"></div>
      </div>
    } @else {
      <div style="width: 100%">
        <div class="p-grid">
          @for (i of [1, 2, 3, 4, 5, 6]; track i) {
            <div class="p-col-4">
              <p-skeleton height="35px" width="100%"></p-skeleton>
            </div>
          }
          <div class="p-col-12 p-mt-5">
            <p-skeleton height="60px" width="100%"></p-skeleton>
          </div>
          <div class="p-col-12 p-mt-5">
            <p-skeleton height="60px" width="100%"></p-skeleton>
          </div>
        </div>
      </div>
    }
  </div>

  <p-dialog
    [header]="'dashboard.dsJustificativaAcaoNova' | translate"
    [showHeader]="true"
    [modal]="true"
    [draggable]="false"
    [style]="{ width: '90%', 'max-width': '1100px', height: '90vh' }"
    [(visible)]="showPopupComentarioCompleto"
    >
    {{ comentarioCompleto }}
  </p-dialog>

