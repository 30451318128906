import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AcaoDefinir } from "../models/acao-definir.model";
import { RequestAnaliseProntidaoTO } from "../tos/request/request-analise-prontidao.to";
import { RequestTesteExecutadoComentarioMedicoTO } from "../tos/request/request-teste-executado-comentario-medico.to";
import { RequestTesteExecutadoLaudoTO } from "../tos/request/request-teste-executado-laudo.to";
import { ResponseCamposColunasTO } from "../tos/response-campos-colunas.to";
import { ResponseAnaliseProntidaoPaginacaoTO } from "../tos/response/response-analise-prontidao-paginado.to";
import { ResponseTesteExecutado } from "../tos/response/response-teste-executado";
import { RequestTesteExecutadoAcaoDefinidaTO } from "../tos/request/request-teste-executado-acao-definida.to";

@Injectable({
  providedIn: "root",
})
export class AnaliseProntidaoService {
  private versionAPI: string = "/report-api/v1";
  private path: string = "/analise-prontidao";

  constructor(private httpClient: HttpClient, private sessionStorageService: SessionStorageService) {}

  /**
   * Carrega do servidor todas as opções de colunas do relatório
   */
  getAllCampos(): Observable<ResponseCamposColunasTO[]> {
    const url = `${this.versionAPI + this.path}/campos`;
    const retorno = this.httpClient.get<ResponseCamposColunasTO[]>(url);
    // console.log(retorno);
    return retorno;
  }

  getAll(filter?: RequestAnaliseProntidaoTO): Observable<ResponseAnaliseProntidaoPaginacaoTO> {
    const url = `${this.versionAPI}${this.path}`;
    const retorno = this.httpClient.post<ResponseAnaliseProntidaoPaginacaoTO>(`${url}`, filter).pipe(
      tap(() => this.salvarFiltros(filter)),
      map((response) => {
        response.dados = response.dados.map((valor) => {
          valor.dtExecucaoTeste = new Date(valor.dtExecucaoTeste);
          valor.dtExecucaoTeste = `${valor.dtExecucaoTeste.toLocaleDateString()} ${valor.dtExecucaoTeste.toLocaleTimeString()}`;
          return valor;
        });
        return response;
      })
    );
    return retorno;
  }

  private salvarFiltros(filtros: RequestAnaliseProntidaoTO) {
    this.sessionStorageService.setItem("filtrosAnaliseProntidao", filtros);
  }

  getFiltros(): RequestAnaliseProntidaoTO {
    return this.sessionStorageService.getItem<RequestAnaliseProntidaoTO>("filtrosAnaliseProntidao");
  }

  getAcoes() {
    const retorno = this.httpClient.get<AcaoDefinir[]>(`/v1/acao/`);
    return retorno;
  }

  create(requestAnaliseProntidaoTO: RequestAnaliseProntidaoTO) {
    return this.httpClient.post<RequestAnaliseProntidaoTO>(
      `${this.versionAPI + this.path}`,
      requestAnaliseProntidaoTO
    );
  }

  atualizarAcaoDefinida(idTesteExecutado: number, request: RequestTesteExecutadoAcaoDefinidaTO) {
    return this.httpClient.put(`/v1/teste-executado/${idTesteExecutado}/trocar-acao-definida`, request);
  }

  getTesteExecutado(id: number): Observable<ResponseTesteExecutado> {
    const retorno = this.httpClient.get<ResponseTesteExecutado>(`/v1/teste-executado/${id}`).pipe(
      map((response) => {
        const parseSubGrupoMapToLabelValue = (r: ResponseTesteExecutado) => {
          r.perguntaLongoGrupos?.forEach((grupo) => {
            grupo?.perguntas?.forEach((pergunta) => {
              if (pergunta.subGrupoMap) {
                const keys = Object.keys(pergunta.subGrupoMap);
                pergunta.subOpcoes = keys.map((k) => ({
                  label: k,
                  value: pergunta.subGrupoMap[k],
                }));
              }
              if (pergunta.flDestacar) {
                grupo.flDestacar = true;
              }
            });
          });
          return r;
        };
        return parseSubGrupoMapToLabelValue(response);
      })
    );
    return retorno;
  }

  createTesteExecutadoLaudoMedico(
    requestTesteExecutadoComentarioMedicoTO: RequestTesteExecutadoComentarioMedicoTO
  ) {
    return this.httpClient.put<RequestTesteExecutadoComentarioMedicoTO>(
      `/v1/teste-executado/laudo-medico/`,
      requestTesteExecutadoComentarioMedicoTO
    );
  }

  definirLaudoEAC(idTesteExecutado: number, requestLaudoEAC: RequestTesteExecutadoLaudoTO) {
    return this.httpClient.put<RequestTesteExecutadoComentarioMedicoTO>(
      `/v1/teste-executado/${idTesteExecutado}/definir-laudo-eac`,
      requestLaudoEAC
    );
  }
}
