import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";

@Injectable({
  providedIn: "root",
})
export class FiltroDashboardFacade {
  private filtrosDashboardChanged$ = new BehaviorSubject<FiltroGraficoDashboad>(new FiltroGraficoDashboad());
  private filtrarDashboard = new Subject<{ emissor: "DASH" | "ACAO"; filtros: FiltroGraficoDashboad }>();
  constructor() {}

  emitFiltrosDashboardChanged(filtros: FiltroGraficoDashboad) {
    this.filtrosDashboardChanged$.next(filtros);
  }

  getFiltrosDashboardChanged() {
    return this.filtrosDashboardChanged$;
  }

  getFiltrarDashboard(ouvinte: "DASH" | "ACAO") {
    return this.filtrarDashboard.pipe(
      filter((r) => {
        return r && r.emissor == ouvinte;
      })
    );
  }

  executarFiltros(emissor: "DASH" | "ACAO", filtros: FiltroGraficoDashboad) {
    this.filtrarDashboard.next({ emissor, filtros });
  }
}
