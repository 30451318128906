import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Chart } from "angular-highcharts";
import moment from "moment";
import { DetalheAcaoADefinir } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { GraficIntrajornadaService, GraficoIntraJornada } from "./grafic-intrajornada.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-grafico-intrajornada",
  templateUrl: "./grafico-intrajornada.component.html",
  styleUrls: ["./grafico-intrajornada.component.scss"],
  providers: [GraficIntrajornadaService],
})
export class GraficoIntrajornadaComponent implements OnInit, OnChanges {
  @Input() detalheAcaoADefinir: DetalheAcaoADefinir;
  chart: Chart;
  chartData: GraficoIntraJornada;
  constructor(
    private graficoIntraJornadaService: GraficIntrajornadaService,
    private translateService: TranslateService
  ) {}

  @HostListener("window:resize")
  onResize() {
    if (this.chartData) {
      this.configurarGrafico(this.chartData);
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    this.chartData = this.graficoIntraJornadaService.prepararDadosParaGrafico(this.detalheAcaoADefinir);
    setTimeout(() => {
      this.configurarGrafico(this.chartData);
    });
  }

  configurarGrafico(data: any) {
    const getTickPositions = () => {
      const ticks = [];
      for (let i = 0; i < 4; i++) {
        ticks.push(
          moment(data.prontidao[0].x)
            .add(7 * i, "d")
            .toDate()
            .getTime()
        );
      }
      return ticks;
    };
    this.chart = new Chart({
      title: {
        text: "",
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
      },
      xAxis: {
        type: "datetime",
        tickPositions: getTickPositions(),
        gridLineWidth: 1,
        minPadding: 0,
        maxPadding: 0,
        labels: {
          enabled: true,
          formatter: function () {
            return moment(this.value).format("DD. MMM");
          },
          align: "left",
          y: -2,
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPositions: [0, 30, 50, 70, 100],
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          formatter: function () {
            return this.isFirst ? "" : this.value + "%";
          },
          align: "right",
          x: -3,
          y: 5,
        },
      },
      series: [
        {
          type: "line",
          color: "#5ab865",
          name: this.translateService.instant("detalheAcaoADefinir.prontidao"),
          data: data.prontidao,
        },
        {
          type: "line",
          color: "#ff8d69",
          name: this.translateService.instant("detalheAcaoADefinir.intrajornada"),
          data: data.intrajornada,
        },
      ],
    });
  }
}
