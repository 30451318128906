import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TopbarService {
  private _eventBus: Subject<any>;

  constructor() {
    this._eventBus = new Subject<any>();
  }

  broadcast(data?: any) {
    this._eventBus.next(data);
  }

  on(): Observable<any> {
    return this._eventBus.asObservable();
  }
}
