import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TestesPendentesListComponent } from "./components/testes-pendentes-list/testes-pendentes-list.component";
import { TestesPendentesComponent } from "./testes-pendentes/testes-pendentes.component";
import { TestesExecutadosComponent } from "./testes-executados/testes-executados.component";

const routes: Routes = [
  { path: "", redirectTo: "pendentes", pathMatch: "full" },
  { path: "pendentes", component: TestesPendentesListComponent },
  { path: "pendentes/:id", component: TestesPendentesComponent },
  { path: "executados/:id", component: TestesExecutadosComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestesRoutingModule {}
