import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponsePerguntaDirecionadaTO } from '../tos/response-pergunta-entrevista-direcionada.to';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EntrevistaDirecionadaService {

  constructor(private httpClient: HttpClient) { }

  private versionAPI: string = '/v1';
  private path: string = '/pergunta-entrevista-direcionada';


  getPerguntas(): Observable<ResponsePerguntaDirecionadaTO> {
    return this.httpClient.get<ResponsePerguntaDirecionadaTO>(`${this.versionAPI}${this.path}`).pipe(
      map(response => {
        response.perguntas.sort((a, b) => a.nrOrdem < b.nrOrdem ? -1 : 1);
        response.dicas.sort((a, b) => a.nrOrdem < b.nrOrdem ? -1 : 1);
        return response;
      })
    );
  }
}

