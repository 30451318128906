import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntrevistaDirecionadaService } from 'src/app/shared/api-services/entrevista-direcionada.service';
import { TesteExecutadoService } from 'src/app/shared/api-services/teste-executado.service';
import { SimNaoEnum } from 'src/app/shared/enums/sim-nao-enum';
import { DicaDirecionada } from 'src/app/shared/models/dica-direcionada';
import { PerguntaDirecionada } from 'src/app/shared/models/pergunta-direcionada.modal';
import { RespostaEntrevistaDirecionada } from 'src/app/shared/models/resposta-entrevista-direcionada';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ResponseRespostaTeste } from 'src/app/shared/tos/response/response-resposta-teste';

@Component({
  selector: 'app-popup-entrevista-direcionada',
  templateUrl: './popup-entrevista-direcionada.component.html',
  styleUrls: ['./popup-entrevista-direcionada.component.scss']
})
export class PopupEntrevistaDirecionadaComponent implements OnInit, OnChanges {
  @ViewChildren('perguntasView') perguntasView: QueryList<ElementRef>;
  @Output() confirmar = new EventEmitter();
  @Input() readOnly: boolean = false;
  @Input() respostasTeste: ResponseRespostaTeste[];
  @Input() loading: boolean = false;

  show: boolean = false;
  destacarNaoRespondidas = false;

  perguntas: PerguntaDirecionada[];
  respostas: RespostaEntrevistaDirecionada[] = [];
  dicas: DicaDirecionada[];

  constructor(
    private entrevistaDirecionadaService: EntrevistaDirecionadaService,
    private translateService: TranslateService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.buscarPerguntasEntrevistaDirecionada();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.respostasTeste) {
      this.montarRespostasTeste();
    }
  }

  montarRespostasTeste() {
    this.respostas = [];
    if (this.respostasTeste?.length) {
      this.perguntas.forEach((p) => {
        const resposta = this.respostasTeste.find(r => r.idPergunta == p.id);
        if (resposta) {
          this.respostas.push(resposta);
        } else {
          const respostaNao = new RespostaEntrevistaDirecionada();
          respostaNao.flOpcao = SimNaoEnum.NAO;
          respostaNao.idPergunta = p.id;
          this.respostas.push(respostaNao);
        }
      })
    }
  }

  buscarPerguntasEntrevistaDirecionada() {
    this.loading = true;
    this.entrevistaDirecionadaService.getPerguntas().subscribe(r => {
      this.loading = false;
      this.perguntas = r.perguntas;
      this.dicas = r.dicas;
      this.respostas = this.montarRespostas(this.perguntas);
      console.log('Perguntas', this.perguntas);
    }, err => {
      this.loading = false;
      throw err;
    })
  }

  montarRespostas(perguntas: PerguntaDirecionada[]) {
    return perguntas.map(p => {
      const resposta = new RespostaEntrevistaDirecionada();
      resposta.idPergunta = p.id;
      return resposta;
    })
  }

  togglePopup() {
    this.show = !this.show;
  }

  enviarRespostas() {
    const respondidas = this.respostas.filter(r => r.flOpcao);
    if (respondidas.length == this.perguntas.length) {
      this.show = false;
      this.exibirMensagemSucesso();
      this.confirmar.emit(respondidas);
    } else {
      this.marcarPerguntasNaoRespondidas();
    }
  }

  marcarPerguntasNaoRespondidas() {
    this.destacarNaoRespondidas = true;
    for (let i = 0; i < this.perguntasView?.toArray().length; i++) {
      if (!this.respostas[i]?.flOpcao) {
        this.perguntasView.get(i).nativeElement.scrollIntoView({ behavior: 'smooth', block: "center" });
        return;
      }
    }
  }

  exibirMensagemSucesso() {
    this.toastService.notifyWithTranslateSuccess('entrevistaDirecionada.menasgemSalvoSucesso');
  }

  cancelar() {
    this.respostas = this.montarRespostas(this.perguntas);
    this.destacarNaoRespondidas = false;
    this.show = false;
  }

}
