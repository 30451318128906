import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { CardAcaoNaoDefinidaComponent } from "./components/card-acao-nao-definida/card-acao-nao-definida.component";
import { FiltrosTestesPendentesComponent } from "./components/filtros-testes-pendentes/filtros-testes-pendentes.component";
import { PopupDefinirAcaoLoteComponent } from "./components/popup-definir-acao-lote/popup-definir-acao-lote.component";
import { TestesPendentesListComponent } from "./components/testes-pendentes-list/testes-pendentes-list.component";
import { TestesPendentesComponent } from "./testes-pendentes/testes-pendentes.component";
import { DetalheAcaoADefinirModule } from "../detalhe-acao-a-definir/detalhe-acao-a-definir.module";
import { FiltrarAreaDestinoPipe } from "./components/testes-pendentes-list/filtrar-area-destino.pipe";
import { TestesRoutingModule } from "./testes.routing.module";
import { FormSugerirAcaoSupervisaoComponent } from "./components/form-sugerir-acao-supervisao/form-sugerir-acao-supervisao.component";
import { TestesExecutadosComponent } from "./testes-executados/testes-executados.component";
import { FormComentarioSaudeComponent } from "./components/form-comentario-saude/form-comentario-saude.component";
import { GraficoIndicadorFadigaComponent } from "../dashboard/components/grafico-indicador-fadiga/grafico-indicador-fadiga.component";
import { QuestionarioAvaliacaoComponent } from "./components/form-comentario-saude/questionario-avaliacao/questionario-avaliacao.component";

@NgModule({
  declarations: [
    TestesPendentesListComponent,
    CardAcaoNaoDefinidaComponent,
    FiltrosTestesPendentesComponent,
    PopupDefinirAcaoLoteComponent,
    TestesPendentesComponent,
    FiltrarAreaDestinoPipe,
    FormSugerirAcaoSupervisaoComponent,
    TestesExecutadosComponent,
    FormComentarioSaudeComponent,
    QuestionarioAvaliacaoComponent,
  ],
  imports: [
    TestesRoutingModule,
    CommonModule,
    SharedModule,
    DetalheAcaoADefinirModule,
    GraficoIndicadorFadigaComponent,
  ],
  exports: [TestesPendentesListComponent, FormComentarioSaudeComponent],
})
export class TestesModule {}
