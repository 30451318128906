<!-- Dialogo de filtro de ação pendente -->
<p-dialog
  id="idDialogFiltroAcoesPendentes"
  [modal]="true"
  [style]="{ width: '765px' }"
  [showHeader]="false"
  [(visible)]="showDialogFiltroPendenteAcaoDefinida"
  (onHide)="esconderFiltros()"
  blockScroll="true"
>
  <div class="titulo" translate>dashboard.tituloFiltro</div>
  <div class="p-grid">
    <div class="p-col-12">
      <div style="margin-bottom: 10px">
        <label translate>dashboard.filtroAcaoSugerida</label>
      </div>
      <app-dropdown
        required
        [options]="listaAcoesPendentes"
        [(ngModel)]="dadosFiltro.idAcaoSugerida"
        scrollHeight="150px"
        name="dadosFiltro.idAcaoSugerida"
        [placeholder]="'dashboard.nenhumaAcaoSugeridaSelecionada' | translate"
      />
    </div>
  </div>

  <div class="p-grid botoes-content">
    <div class="p-col-6">
      <button type="button" class="botao-cancelar" (click)="esconderFiltros()">
        <span translate>dashboard.cancelar</span>
      </button>
    </div>
    <div class="p-col-6">
      <button type="button" class="botao-confirmar" (click)="pesquisar()">
        <span translate>dashboard.confirmar</span>
      </button>
    </div>
  </div>
</p-dialog>
