import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import { XAxisOptions } from "highcharts";
import moment from "moment";
import { HistoricoNormalEnum } from "src/app/shared/enums/historico-normal.enum";
import { DetalheAcaoADefinir } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { HistoricoAcao } from "src/app/shared/models/historico-acao.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-detalhe-acao-a-definir",
  templateUrl: "detalhe-acao-a-definir.component.html",
  styleUrls: ["detalhe-acao-a-definir.component.css"],
})
export class DetalheAcaoADefinirComponent implements OnInit, OnChanges {
  quantidadeLinhasAcao: number = 2;
  indexItemAcao: number = 0;
  chart: Chart;
  historicoAcoes: HistoricoAcao[];
  colunas: any[];
  exibeDetalhe: boolean;
  zones = [];
  seriePrincipal: any[] = [];
  serieIntrajornada: any[] = [];
  dataInicial: any;
  showLabelUltimoTeste: boolean = false;
  showLabelUltimoTesteIntrajornada: boolean = false;
  @Input() ApenasGrafico: boolean = false;
  exibeId: boolean = false;
  cdEvoluiuRegrediu: string;
  interval: any;
  zonesIntrajornada = [];
  showPopupComentarioCompleto: boolean = false;
  comentarioCompleto: string;

  @Input() isLoading: boolean;
  @Input() detalheAcaoADefinir: DetalheAcaoADefinir;
  @Output() voltar = new EventEmitter();
  @Output() acaoDefinida = new EventEmitter();

  constructor(public translateService: TranslateService) {}

  ngOnInit() {
    this.exibeId = environment.cliente == "vale";
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.detalheAcaoADefinir?.currentValue) {
      this.zones = [];
      this.seriePrincipal = [];
      this.serieIntrajornada = [];
      this.carregarDadosDetalhamento();
      this.historicoAcoes = this.detalheAcaoADefinir.histAcao;
      this.cdEvoluiuRegrediu = this.detalheAcaoADefinir.cdEvoluiuRegrediu;

      this.detalheAcaoADefinir.histNivelResultado?.forEach((nivelResultado, i) => {
        this.seriePrincipal.push({
          name: new Date(nivelResultado.dtExecucao).getTime(),
          y: nivelResultado.vlItem,
          x: new Date(nivelResultado.dtExecucao).getTime(),
        });
      });

      this.detalheAcaoADefinir.histNivelResultadoIntrajornada?.forEach((nivelResultado, j) => {
        this.serieIntrajornada.push({
          name: new Date(nivelResultado.dtExecucao).getTime(),
          y: nivelResultado.vlItem,
          x: new Date(nivelResultado.dtExecucao).getTime(),
        });
      });

      const ultimoPonto: any = this.seriePrincipal[this.seriePrincipal.length - 1];

      if (ultimoPonto) {
        this.seriePrincipal[this.seriePrincipal.length - 1] = {
          name: ultimoPonto.name,
          x: ultimoPonto.x,
          y: ultimoPonto.y,
          marker: { fillColor: "#FFFFFF" },
        };
      }

      this.detalheAcaoADefinir.histAcao.forEach((histAcao) => {
        histAcao.dtExecucao = new Date(histAcao.dtExecucao);
        histAcao.dtAcaoDefinida = histAcao.dtAcaoDefinida ? new Date(histAcao.dtAcaoDefinida) : null;
      });
    }
  }

  buildZones(data) {
    var zones = [],
      i = -1,
      len = data.length,
      current,
      previous,
      dashStyle,
      value;

    if (len < 1) {
      return;
    }
    while (data[++i] === null);
    zones.push({
      value: data[i].x,
    });

    while (++i < len) {
      previous = data[i - 1].y;
      current = data[i].y;
      dashStyle = "";

      if (previous !== null && current === null) {
        dashStyle = "solid";
        value = data[i - 1].x;
      } else if (previous === null && current !== null) {
        dashStyle = "dot";
        value = data[i].x;
      }

      if (dashStyle) {
        zones.push({
          dashStyle: dashStyle,
          value: value,
        });
      }
    }

    console.log(zones);
    return zones;
  }

  exibirComentarioCompleto(comentarioCompleto: string) {
    this.showPopupComentarioCompleto = true;
    this.comentarioCompleto = comentarioCompleto;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.interval = setInterval((_) => {
        const g: any = document.querySelector(
          ".highcharts-markers.highcharts-series-0.highcharts-line-series.highcharts-color-0.highcharts-tracker"
        );
        if (g != null && g.getElementsByTagName("path") != null) {
          const paths: any[] = g.getElementsByTagName("path");
          if (paths.length > 0) {
            const valorCoordenadasUltimoPonto: any = paths[paths.length - 1].getAttribute("d").split(" ");
            const valorCoordenadasPenultimoPonto: any = paths[paths.length - 2].getAttribute("d").split(" ");

            // console.log(valorCoordenadasUltimoPonto);
            this.showLabelUltimoTeste = true;
            const divUltimTeste: HTMLElement = document.querySelector("#ultimoTeste");
            if (divUltimTeste != null) {
              if (valorCoordenadasPenultimoPonto[2] >= valorCoordenadasUltimoPonto[2]) {
                divUltimTeste.style.marginTop = Number(valorCoordenadasUltimoPonto[2]) + 20 + "px";
              } else {
                divUltimTeste.style.marginTop = Number(valorCoordenadasUltimoPonto[2]) + 55 + "px";
              }
              divUltimTeste.style.marginLeft = Number(valorCoordenadasUltimoPonto[1] - 12) + "px";
              clearInterval(this.interval);
            }
          }
        }
      }, 1000);
    }, 0);
  }

  configurarGrafico(): void {
    this.chart = null;
    const width: HTMLElement = document.querySelector("#containeGrafico");

    // Point of interest options
    const xAxis: XAxisOptions = {
      type: "datetime",
      tickInterval: 3,
      labels: {
        enabled: true,
        formatter: function () {
          return moment(new Date(this.value)).format("DD/MM/YY");
        },
      },
    };

    xAxis.crosshair = false;
    // xAxis.categories = [].concat(this.seriePrincipal.map((v) => v.x));
    console.log(xAxis.categories);

    this.chart = new Chart({
      legend: {
        enabled: true,
      },
      xAxis: xAxis,
      yAxis: {
        title: { text: "" },
        tickInterval: 10,
        //plotLines: [{ value: 30, width: 1 }, { value: 50, width: 1 }, { value: 70, width: 1 }, { value: 100, width: 1 }],
        min: 0,
        max: 100,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        width: width?.offsetWidth - 50,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: "",
        align: "center",
        verticalAlign: "middle",
        y: 20,
        x: -55,
      },
      tooltip: {
        followPointer: true,
        formatter: function () {
          return moment(new Date(this.key)).format("DD/MM/YY HH:mm");
        },
      },
      //series: auxSeries,
    });

    var temIntrajornada =
      this.detalheAcaoADefinir.histNivelResultadoIntrajornada != undefined &&
      this.detalheAcaoADefinir.histNivelResultadoIntrajornada != null &&
      this.detalheAcaoADefinir.histNivelResultadoIntrajornada.length > 0;

    this.chart.addSeries(
      {
        zoneAxis: "x",
        connectNulls: true,
        color: "#3853AC",
        zones: this.buildZones(this.seriePrincipal),
        type: "line",
        name: this.translateService.instant("detalheAcaoADefinir.prontidao"),
        data: this.seriePrincipal,
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
      !temIntrajornada,
      false
    );

    if (temIntrajornada) {
      this.chart.addSeries(
        {
          connectNulls: true,
          zoneAxis: "x",
          zones: this.buildZones(this.serieIntrajornada),
          color: "#db4d4d",
          type: "line",
          name: this.translateService.instant("detalheAcaoADefinir.intrajornada"),
          data: this.serieIntrajornada,
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        true,
        false
      );
    }
  }

  carregarDadosDetalhamento(): void {
    this.carregarLinhasHistoricoClassificacaoFinal();
    this.configurarGrafico();
  }

  carregarLinhasHistoricoClassificacaoFinal(): void {
    if (this.detalheAcaoADefinir) {
      this.detalheAcaoADefinir.histClassifFinal.forEach((valor, i) => {
        if (i % 5 === 4) {
          valor.border = "none";
        } else {
          valor.border = "1px solid #dddddd";
        }
      });
    }
  }

  voltarDashboard(): void {
    this.voltar.emit();
    this.exibeDetalhe = false;
  }

  isProgrediu(): boolean {
    return this.cdEvoluiuRegrediu == HistoricoNormalEnum.EVOLUIU;
  }

  isRegrediu(): boolean {
    return this.cdEvoluiuRegrediu == HistoricoNormalEnum.REGREDIU;
  }
}
