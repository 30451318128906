import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService, SelectItem, TreeNode } from "primeng/api";
import { RequestDefinirAcaoLoteTO } from "src/app/shared/models/request-definir-acao-em-lote.to";
import { DashboardService } from "src/app/shared/services/dashboard.service";

@Component({
  selector: "app-popup-definir-acao-lote",
  templateUrl: "./popup-definir-acao-lote.component.html",
  styleUrls: ["./popup-definir-acao-lote.component.scss"],
})
export class PopupDefinirAcaoLoteComponent implements OnInit {
  @Output() onChangeHierarquia = new EventEmitter();
  @Output() sucessoAcoesDefinidas = new EventEmitter();
  @Input() hierarquias: TreeNode[];
  @Input() listaAcoesPendentes: SelectItem[] = [];
  @Input() listaAcoes: SelectItem[] = [];

  showDialogDefinirAcaoEmLote: boolean = false;
  requestDefinirAcaoLoteTO: RequestDefinirAcaoLoteTO = new RequestDefinirAcaoLoteTO();
  hierarquiaSelecionadaDefinirAcao: TreeNode = {};

  idAcaoSugeridaSelecionado: any = null;
  recebeLoadButton: boolean = false;

  constructor(
    private render2: Renderer2,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  exibirPopup() {
    this.showDialogDefinirAcaoEmLote = true;
  }

  esconderPopup() {
    this.showDialogDefinirAcaoEmLote = false;
  }

  selecionarAcaoSugerida(itemSelecionado: any): void {
    // acao sugerida
    if (itemSelecionado != null) {
      this.requestDefinirAcaoLoteTO.idAcaoSugerida = itemSelecionado.id;
    } else {
      this.requestDefinirAcaoLoteTO.idAcaoSugerida = null;
    }
  }

  selecionarAcaoDefinida(itemSelecionado: any): void {
    // acao definida
    if (itemSelecionado != null) {
      this.requestDefinirAcaoLoteTO.idAcaoDefinida = itemSelecionado.id;
    } else {
      this.requestDefinirAcaoLoteTO.idAcaoDefinida = null;
    }
  }

  /**
   * Callback invocada quando um node é selecionado para definir uma ação em lote.
   *
   * @param event Objeto com o node selecionado e o evento do browser.
   */
  onNodeSelectDefinirAcao(event, op): void {
    this.requestDefinirAcaoLoteTO.idHierarquia = event.node.data.id;
    this.requestDefinirAcaoLoteTO.idAcaoDefinida = null;
    this.onChangeHierarquia.emit(this.requestDefinirAcaoLoteTO.idHierarquia);
    op.hide();
  }

  /**
   * Callback invocada sempre que o usuário selecionar a definição de ação
   * em lote.
   */
  definirAcaoEmLote(): void {
    if (
      this.requestDefinirAcaoLoteTO.idAcaoDefinida != null ||
      this.requestDefinirAcaoLoteTO.idAcaoSugerida != null
    ) {
      this.recebeLoadButton = true;
      this.dashboardService.definirAcaoEmLote(this.requestDefinirAcaoLoteTO).subscribe(
        (resp) => {
          this.recebeLoadButton = false;
          this.messageService.add({
            key: "tc",
            summary: this.translateService.instant("dashboard.sumarioMensagemAcaoDefinidaSucesso"),
            severity: "success",
            detail: this.translateService.instant("dashboard.mensagemAcaoDefinidaSucesso"),
          });
          this.esconderPopup();
          this.sucessoAcoesDefinidas.emit();
          this.requestDefinirAcaoLoteTO = new RequestDefinirAcaoLoteTO();
        },
        (error) => {
          this.recebeLoadButton = false;
          throw error;
        }
      );
    }
  }

  // TODO refatorar
  abrirHierarquiasDefinirAcao(op: any, event): void {
    op.toggle(event);
    setTimeout((_) => {
      const containerHierarquia: any = document.querySelector("#hierarquiaDefinirAcao > div");
      const tamanhoContainerHierarquia: number = document.querySelector(
        "#idContainerHierarquiaDefinirAcao"
      ).clientWidth;
      const top: any = document
        .querySelector("#idContainerHierarquiaDefinirAcao")
        .getBoundingClientRect().top;
      const left: any = document
        .querySelector("#idContainerHierarquiaDefinirAcao")
        .getBoundingClientRect().left;
      this.render2.setStyle(containerHierarquia, "width", `${tamanhoContainerHierarquia - 29}px`);
      this.render2.setStyle(containerHierarquia, "top", "");
      this.render2.setStyle(containerHierarquia, "overflow", "auto");
      this.render2.setStyle(containerHierarquia, "height", "250px");
      this.render2.setStyle(containerHierarquia, "top", `${top - 76}px`);
      this.render2.setStyle(containerHierarquia, "left", `${left - 66}px`);
    }, 0);
  }
}
