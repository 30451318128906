import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { DetalheAcaoADefinirComponent } from "./detalhe-acao-a-definir.component";
import { GraficoIntrajornadaComponent } from "./grafico-intrajornada/grafico-intrajornada.component";
import { PopupEntrevistaDirecionadaComponent } from "./popup-entrevista-direcionada/popup-entrevista-direcionada.component";
import { SkeletonModule } from "primeng/skeleton";
import { GraficoIndicadorFadigaComponent } from "../dashboard/components/grafico-indicador-fadiga/grafico-indicador-fadiga.component";

@NgModule({
  declarations: [
    DetalheAcaoADefinirComponent,
    GraficoIntrajornadaComponent,
    PopupEntrevistaDirecionadaComponent,
  ],
  imports: [CommonModule, SharedModule, SkeletonModule, GraficoIndicadorFadigaComponent],
  exports: [DetalheAcaoADefinirComponent, PopupEntrevistaDirecionadaComponent],
})
export class DetalheAcaoADefinirModule {}
