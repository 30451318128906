<div class="p-grid">
  @for (item of questionario; track item) {
    <div class="p-col-12 p-md-3">
      <label class="label-input" translate> analiseProntidao.{{ item.field }}</label>
      @for (opcao of item.options; track opcao) {
        <div class="p-mb-2">
          @if (!item.multiple) {
            <p-radioButton
              [name]="item.field + opcao.value"
              [inputId]="item.field + opcao.value"
              [value]="opcao.value"
              [(ngModel)]="model[item.field]"
              />
          }
          @if (item.multiple) {
            <p-checkbox
              [name]="item.field + opcao.value"
              [inputId]="item.field + opcao.value"
              [value]="opcao.value"
              [(ngModel)]="model[item.field]"
              />
          }
          <label [for]="item.field + opcao.value" class="ml-2" translate>
            {{ opcao.label }}
          </label>
        </div>
      }
    </div>
  }
</div>
