import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { ResponsePendenteAcaoDefinidaTO } from "src/app/shared/models/response-pendente-acao-definida.to";

@Component({
  selector: "app-card-acao-nao-definida",
  templateUrl: "card-acao-nao-definida.component.html",
  styleUrls: ["card-acao-nao-definida.component.css"],
})
export class CardAcaoNaoDefinidaComponent implements OnInit {
  @Input() testePendenteAcaoDefinida: ResponsePendenteAcaoDefinidaTO;
  @Input() showIdAcao: boolean;
  @Output() onClick: EventEmitter<ResponsePendenteAcaoDefinidaTO> = new EventEmitter();
  flExibirFadiga: boolean;

  constructor(private translateService: TranslateService, private contratoService: ContratoService) {}

  ngOnInit() {
    this.contratoService.getContrato().subscribe((configuracoes) => {
      this.flExibirFadiga = configuracoes.flExibeFadiga;
    });
  }

  onClickCard(testePendenteAcaoDefinica: ResponsePendenteAcaoDefinidaTO): void {
    testePendenteAcaoDefinica.classeCssUsuarioSelecionado = "card-active";
    this.onClick.emit(testePendenteAcaoDefinica);
  }

  getHint(tipo: "hintBancoCheio" | "hintBancoVazio" | "hintIntrajornada") {
    return this.translateService.instant("cardAcaoNaoDefinida." + tipo);
  }
}
