@if (idUsuario && flExibirFadiga) {
<div class="p-grid">
  <div class="p-col-12">
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <em class="material-icons"> show_chart </em>
          <span class="title" translate> indicadorFadiga.analiseProloganda </span>
        </div>
        @if (nmCliente) {
        <div class="card-pagination">
          <div class="legenda-media" translate>
            {{ nmCliente }}
          </div>
        </div>
        }
      </div>
      <div class="card-body" style="min-height: 300px">
        <app-loader [loading]="loadingDadosUsuario" />
        @if (!loadingDadosUsuario && !dadosGraficoUsuario) {
        <div class="sem-dados">indicadoresFadiga.semDados</div>
        } @if (!loadingDadosUsuario && dadosGraficoUsuario) {
        <div class="p-grid">
          <div class="p-col-10 p-md-11 p-offset-2 p-md-offset-1">
            <div class="nome-usuario-container">
              <span class="titulo-usuario"> {{ "indicadorFadiga.usuario" | translate }}: </span>
              <span class="nome-usuario"> {{ username }}</span>
            </div>
          </div>
          <div class="p-col-2 p-md-1">
            <div class="escala-grafico">
              <div class="escala-grafico_nome" translate>indicadorFadiga.altoRisco</div>
              <app-escala-grafico></app-escala-grafico>
              <div class="escala-grafico_nome" translate>indicadorFadiga.baixoRisco</div>
            </div>
          </div>
          <div class="p-col-10 p-md-11">
            <div [chart]="chart"></div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
}
