import { Injectable } from "@angular/core";
import moment from "moment";
import { DetalheAcaoADefinir } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { HistoricoNivelResultado } from "src/app/shared/models/historio-nivel-resultado.model";

interface SerieGrafico {
  name: any;
  y: number;
  x: number;
}

export interface GraficoIntraJornada {
  prontidao: SerieGrafico[];
  intrajornada: SerieGrafico[];
}

@Injectable()
export class GraficIntrajornadaService {
  constructor() {}

  prepararDadosParaGrafico(detalheAcaoDefinir: DetalheAcaoADefinir): GraficoIntraJornada {
    const converterParaGrafico = (item: HistoricoNivelResultado) => ({
      name: moment(item.dtExecucao).format("DD/MM/YYYY HH:mm"),
      y: item.vlItem,
      x: new Date(item.dtExecucao).getTime(),
    });

    const tratarDados = (itens: HistoricoNivelResultado[]) =>
      itens.filter((item) => item.vlItem !== null && item.vlItem !== undefined).map(converterParaGrafico);

    const prontidao = tratarDados(detalheAcaoDefinir.histNivelResultado);
    const intrajornada = tratarDados(detalheAcaoDefinir.histNivelResultadoIntrajornada);
    return { prontidao, intrajornada };
  }
}
