export interface QuestionarioAvalaicao {
  field: string;
  multiple?: boolean;
  options: { label: string; value: string }[];
}

export const AVALIACAO_PERCEPTIVA: QuestionarioAvalaicao[] = [
  {
    field: "dsDeambulacao",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Arrastado",
        value: "Arrastado",
      },
      {
        label: "analiseProntidao.opcoes.Desequilibrado",
        value: "Desequilibrado",
      },
    ],
  },
  {
    field: "dsFace",
    multiple: true,

    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Dor",
        value: "Dor",
      },
      {
        label: "analiseProntidao.opcoes.Tristeza",
        value: "Tristeza",
      },
      {
        label: "analiseProntidao.opcoes.Abatido",
        value: "Abatido",
      },
      {
        label: "analiseProntidao.opcoes.Sonolento",
        value: "Sonolento",
      },
      {
        label: "analiseProntidao.opcoes.Ansioso",
        value: "Ansioso",
      },
      {
        label: "analiseProntidao.opcoes.Agitado",
        value: "Agitado",
      },
    ],
  },
  {
    field: "dsFala",
    multiple: true,

    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Lenta",
        value: "Lenta",
      },
      {
        label: "analiseProntidao.opcoes.Euforica",
        value: "Euforica",
      },
      {
        label: "analiseProntidao.opcoes.Ausente",
        value: "Ausente",
      },
      {
        label: "analiseProntidao.opcoes.Consusa",
        value: "Consusa",
      },
    ],
  },
  {
    field: "dsResistente",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
];

export const AVALIACAO_MENTAL: QuestionarioAvalaicao[] = [
  {
    field: "dsOrientacaoTemporal",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
  {
    field: "dsHumor",
    multiple: true,
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Euforica",
        value: "Euforico",
      },
      {
        label: "analiseProntidao.opcoes.Confuso",
        value: "Confuso",
      },
      {
        label: "analiseProntidao.opcoes.Triste",
        value: "Triste",
      },
    ],
  },
  {
    field: "dsMemoriaImediata",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
  {
    field: "dsMemoriaRemota",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
  {
    field: "dsMemoriaRecente",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
];

export const AVALIACAO_MOTORA: QuestionarioAvalaicao[] = [
  {
    field: "dsMotoraEquilibrio",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
  {
    field: "dsTremores",
    options: [
      {
        label: "analiseProntidao.opcoes.Normal",
        value: "Normal",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
  {
    field: "dsTonteirasZumbido",
    multiple: true,
    options: [
      {
        label: "analiseProntidao.opcoes.Tonteiras",
        value: "Tonteiras",
      },
      {
        label: "analiseProntidao.opcoes.Zumbido",
        value: "Zumbido",
      },
    ],
  },
  {
    field: "dsSinalRombert",
    options: [
      {
        label: "analiseProntidao.opcoes.Equilibrado",
        value: "Equilibrado",
      },
      {
        label: "analiseProntidao.opcoes.Desequilibrado",
        value: "Desequilibrado",
      },
    ],
  },
  {
    field: "dsMarchaEquilibrio",
    options: [
      {
        label: "analiseProntidao.opcoes.Equilibrado",
        value: "Equilibrado",
      },
      {
        label: "analiseProntidao.opcoes.Alterada",
        value: "Alterada",
      },
    ],
  },
];

export const AVALIACAO_GERAL: QuestionarioAvalaicao[] = [
  {
    field: "dsAlimentouUltimas3Horas",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsUtilizaMedicamento",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsDormiuBem",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsSentindoDor",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsConsumiuBebidaAlcoolica",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsProblemaEmocional",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
  {
    field: "dsDesejaAjuda",
    options: [
      {
        label: "analiseProntidao.opcoes.Sim",
        value: "Sim",
      },
      {
        label: "analiseProntidao.opcoes.Nao",
        value: "Nao",
      },
    ],
  },
];
