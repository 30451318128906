import { UserGuideStep } from "src/app/shared/models/user-guide-step.model";
import { Util } from "src/app/shared/services/util.service";

export const testesPendentesListTutorial = (
  isMobile: boolean,
  navegarParaDashboard: () => void
): UserGuideStep[] => [
  {
    title: "tutorial.dashboard.tituloAcoesEmAberto",
    text: `tutorial.dashboard.acoesPendentes`,
    element: `[data-tutorial='${isMobile ? "testes-pendente-list-mobile" : "testes-pendente-list"}']`,
    position: "left",
    backButtonText: "paginacao.anterior",
    nextButtonText: "paginacao.proximo",
    beforeShowPromise: () =>
      Util.waitUntil(
        () =>
          !!document.querySelector(
            `[data-tutorial='${isMobile ? "testes-pendente-list-mobile" : "testes-pendente-list"}']`
          )
      ),
  },
  {
    title: "tutorial.dashboard.tituloCardAcaoPendente",
    text: `tutorial.dashboard.cardAcaoPendente`,
    element: "[data-tutorial='card-acao-pendente']",
    nextButtonText: "paginacao.proximo",
    position: "left",
    whenHide: function () {
      if (isMobile) {
        navegarParaDashboard();
      }
    },
  },
];
