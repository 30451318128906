import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SelectItem, TreeNode } from "primeng/api";
import { Subscription } from "rxjs";

@Component({
  selector: "app-filtros-testes-pendentes",
  templateUrl: "./filtros-testes-pendentes.component.html",
  styleUrls: ["./filtros-testes-pendentes.component.scss"],
})
export class FiltrosTestesPendentesComponent implements OnInit, OnDestroy {
  @Output() filtrar = new EventEmitter();
  @Input() listaAcoesPendentes: SelectItem[] = [];
  @Input() hierarquias: TreeNode[];

  showDialogFiltroPendenteAcaoDefinida: boolean = false;
  dadosFiltro: any = {};
  hierarquiaSelecionada: TreeNode[] = null;
  subs: Subscription[] = [];

  constructor() {}

  ngOnInit() {}

  pesquisar() {
    const filtros = Object.assign({}, this.dadosFiltro);
    filtros.idAcaoSugerida = (filtros.idAcaoSugerida && filtros.idAcaoSugerida.id) || null;
    this.esconderFiltros();
    this.filtrar.emit(filtros);
  }

  exibirFiltros(): void {
    this.showDialogFiltroPendenteAcaoDefinida = true;
  }

  esconderFiltros(): void {
    this.showDialogFiltroPendenteAcaoDefinida = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
