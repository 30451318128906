import { Util } from "../../services/util.service";
import { ResponseTesteExecutado } from "../response/response-teste-executado";

export class RequestTesteExecutadoComentarioMedicoTO {
  idTesteExecutado: number;
  idAcaoDefinida: number;
  dsLaudoMedico: string;
  dsPressaoArterialSistole: string;
  dsPressaoArterialDiastole: string;
  dsFrequenciaCardiaca: string;
  dsFrequenciaRespiratoria: string;
  dsTemperatura: string;
  dsMedicamento: string;
  dsQueixa: string;
  dsPeso: string;
  dsAltura: string;
  dsCircunferenciaAbnominal: string;
  dsCervical: string;
  dsGlicemia: string;
  dsTesteAlcoolemia: string;
  dsTestesSaudeAdicionais: string;
  dsSaturacao: string;

  dsDeambulacao: string;
  dsFace: string[];
  dsFala: string[];
  dsResistente: string;
  dsOrientacaoTemporal: string;
  dsHumor: string[];
  dsMemoriaImediata: string;
  dsMemoriaRemota: string;
  dsMemoriaRecente: string;
  dsMotoraEquilibrio: string;
  dsTremores: string;
  dsTonteirasZumbido: string[];
  dsSinalRombert: string;
  dsMarchaEquilibrio: string;
  dsAlimentouUltimas3Horas: string;
  dsDormiuBem: string;
  dsSentindoDor: string;
  dsProblemaEmocional: string;
  dsDesejaAjuda: string;
  dsUtilizaMedicamento: string;
  dsConsumiuBebidaAlcoolica: string;

  fromTesteExecutado(testeExecutado: ResponseTesteExecutado) {
    this.idTesteExecutado = testeExecutado.idTesteExecutado;
    this.dsLaudoMedico = testeExecutado.dsLaudoMedico;
    this.dsPressaoArterialSistole = testeExecutado.dsPressaoArterialSistole;
    this.dsPressaoArterialDiastole = testeExecutado.dsPressaoArterialDiastole;
    this.dsFrequenciaCardiaca = testeExecutado.dsFrequenciaCardiaca;
    this.dsFrequenciaRespiratoria = testeExecutado.dsFrequenciaRespiratoria;
    this.dsTemperatura = testeExecutado.dsTemperatura;
    this.dsMedicamento = testeExecutado.dsMedicamento;
    this.dsQueixa = testeExecutado.dsQueixa;
    this.dsPeso = testeExecutado.dsPeso;
    this.dsAltura = testeExecutado.dsAltura;
    this.dsCircunferenciaAbnominal = testeExecutado.dsCircunferenciaAbnominal;
    this.dsCervical = testeExecutado.dsCervical;
    this.dsGlicemia = testeExecutado.dsGlicemia;
    this.dsTesteAlcoolemia = testeExecutado.dsTesteAlcoolemia;
    this.dsTestesSaudeAdicionais = testeExecutado.dsTestesSaudeAdicionais;
    this.dsSaturacao = testeExecutado.dsSaturacao;
    this.idAcaoDefinida = testeExecutado.idAcaoDefinidaLaudoMedico;

    this.dsDeambulacao = testeExecutado.dsDeambulacao;
    this.dsFace = testeExecutado.dsFace?.split(";");
    this.dsFala = testeExecutado.dsFala?.split(";");
    this.dsResistente = testeExecutado.dsResistente;
    this.dsOrientacaoTemporal = testeExecutado.dsOrientacaoTemporal;
    this.dsHumor = testeExecutado.dsHumor?.split(";");
    this.dsMemoriaImediata = testeExecutado.dsMemoriaImediata;
    this.dsMemoriaRemota = testeExecutado.dsMemoriaRemota;
    this.dsMemoriaRecente = testeExecutado.dsMemoriaRecente;
    this.dsMotoraEquilibrio = testeExecutado.dsMotoraEquilibrio;
    this.dsTremores = testeExecutado.dsTremores;
    this.dsTonteirasZumbido = testeExecutado.dsTonteirasZumbido?.split(";");
    this.dsSinalRombert = testeExecutado.dsSinalRombert;
    this.dsMarchaEquilibrio = testeExecutado.dsMarchaEquilibrio;
    this.dsAlimentouUltimas3Horas = testeExecutado.dsAlimentouUltimas3Horas;
    this.dsDormiuBem = testeExecutado.dsDormiuBem;
    this.dsSentindoDor = testeExecutado.dsSentindoDor;
    this.dsProblemaEmocional = testeExecutado.dsProblemaEmocional;
    this.dsDesejaAjuda = testeExecutado.dsDesejaAjuda;
    this.dsUtilizaMedicamento = testeExecutado.dsUtilizaMedicamento;
    this.dsConsumiuBebidaAlcoolica = testeExecutado.dsConsumiuBebidaAlcoolica;
    return this;
  }

  toApi() {
    const data = Util.deepClone(this);
    data.dsFace = data.dsFace?.join(";") as any;
    data.dsFala = data.dsFala?.join(";") as any;
    data.dsHumor = data.dsHumor?.join(";") as any;
    data.dsTonteirasZumbido = data.dsTonteirasZumbido?.join(";") as any;
    return data;
  }
}
