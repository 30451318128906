import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { ToastService } from "src/app/shared/services/toast.service";
import { MessageService as MessageServiceLocal } from "src/app/shared/services/message.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { TipoMessageEnum } from "src/app/shared/enums/tipo-message.enum";

@Injectable({
  providedIn: "root",
})
export class TestePendenteService {
  testeSelecionado = new ReplaySubject<number>();

  constructor(
    private toastService: ToastService,
    private messageServiceLocal: MessageServiceLocal,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  notificarTesteSelecionado(idTeste: number) {
    this.testeSelecionado.next(idTeste);
  }

  onTesteSaveScucess() {
    this.toastService.notifyWithTranslateSuccess("salvoSucesso");
    this.messageServiceLocal.speak(
      TipoMessageEnum.CARREGAR_TESTES_PENDENTES,
      null
    );
    this.messageService.add({
      key: "tc",
      summary: this.translateService.instant(
        "dashboard.sumarioMensagemAcaoDefinidaSucesso"
      ),
      severity: "success",
      detail: this.translateService.instant(
        "dashboard.mensagemAcaoDefinidaSucesso"
      ),
    });
  }
}
