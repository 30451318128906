import { ScoreFadigaEnum } from "./../enums/score-fadiga.enum";
import moment from "moment";
import { FormacaoBancoDadosEnum } from "../enums/formacao-banco-dados.enum";
import { SimNaoEnum } from "../enums/sim-nao-enum";
import { Util } from "../services/util.service";

export class FiltroGraficoDashboad {
  dtInicio: any;
  dtFim: any;
  horaInicio: any;
  horaFim: any;
  idHierarquia: number[];
  flIncluirFilhas: boolean = true;
  situacaoImpulsividadeEstimada: FormacaoBancoDadosEnum = null;
  idCargo: number[];
  idTurno: number[];
  idNivelResultado: number;
  id?: number;
  cdMatriculaUsuario?: any;
  flApenasUsuarioAtivos?: boolean;
  flIntrajornada: SimNaoEnum;
  dsFormacao: string;
  flIncluirAvisoAusencia: boolean;
  dtAnalise: any;
  scoreFadiga: ScoreFadigaEnum = null;
  idStr: string;

  constructor() {
    this.dtInicio = new Date();
    this.dtInicio.setHours(0, 0, 0);
    this.dtFim = new Date();
    this.dtFim.setHours(23, 59, 59);

    this.horaInicio = new Date();
    this.horaInicio.setHours(0, 0, 0);
    this.horaFim = new Date();
    this.horaFim.setHours(23, 59, 59);
    this.flApenasUsuarioAtivos = true;
    this.flIncluirAvisoAusencia = true;
  }

  formatarFiltros(filtros: FiltroGraficoDashboad) {
    Object.assign(this, filtros);
    this.dtInicio = this.dtInicio ? new Date(this.dtInicio) : null;
    this.dtFim = this.dtFim ? new Date(this.dtFim) : null;
    if (filtros.dtInicio) {
      this.horaInicio = new Date(filtros.dtInicio);
    }
    if (filtros.dtFim) {
      this.horaFim = new Date(filtros.dtFim);
    }
    this.dtAnalise = this.dtAnalise ? moment(this.dtAnalise, "YYYY-MM-DD").toDate() : null;
    return this;
  }

  toApi(id?: number, idStr?: string) {
    const filtros = Object.assign(new FiltroGraficoDashboad(), this);

    if (typeof filtros.dtInicio != "string") {
      filtros.dtInicio.setHours(filtros.horaInicio.getHours(), filtros.horaInicio.getMinutes(), 0);
    }
    if (typeof filtros.dtFim != "string") {
      filtros.dtFim.setHours(filtros.horaFim.getHours(), filtros.horaFim.getMinutes(), 59);
    }

    if (filtros.dtAnalise && typeof filtros.dtAnalise != "string") {
      filtros.dtAnalise.setHours(0, 0, 0);
    }

    if (!filtros.situacaoImpulsividadeEstimada) {
      delete filtros.situacaoImpulsividadeEstimada;
    }
    if (idStr) {
      filtros.idStr = idStr;
    }
    if (id) {
      filtros.id = id;
    }
    filtros.dtAnalise = filtros.dtAnalise ? Util.dateToStringByFormat(filtros.dtAnalise, "YYYY-MM-DD") : null;
    filtros.dtInicio = filtros.dtInicio
      ? Util.dateToStringByFormat(new Date(filtros.dtInicio), "YYYY-MM-DDTHH:mm:ss")
      : null;
    filtros.dtFim = filtros.dtFim
      ? Util.dateToStringByFormat(new Date(filtros.dtFim), "YYYY-MM-DDTHH:mm:ss")
      : null;
    delete filtros.horaInicio;
    delete filtros.horaFim;

    return filtros;
  }
}
