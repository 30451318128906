<div class="grafico-container">
  <div class="arrow-container">
    <span class="texto-excelente" translate>dashboard.excelente</span>
    <img src="assets/images/escala-grafico-green.svg" class="arrow" alt="Seta de avaliação.">
    <span class="texto-precisa-melhorar" translate>dashboard.precisaMelhorar</span>
  </div>
  <div style="margin-left: 5px; flex: 1">
    <div [chart]="chart" style="height: 315px; width: 100%"></div>
  </div>
</div>
