import { Component, Input } from "@angular/core";

import { QuestionarioAvalaicao } from "../questionarios-avaliacao";
import { RequestTesteExecutadoComentarioMedicoTO } from "src/app/shared/tos/request/request-teste-executado-comentario-medico.to";
import { RadioButtonModule } from "primeng/radiobutton";

@Component({
  selector: "app-questionario-avaliacao",
  templateUrl: "./questionario-avaliacao.component.html",
  styleUrls: ["./questionario-avaliacao.component.scss"],
})
export class QuestionarioAvaliacaoComponent {
  @Input() model: RequestTesteExecutadoComentarioMedicoTO;
  @Input() questionario: QuestionarioAvalaicao[];
}
