import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class TipoDispositivoService {
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  getInfoDevice() {
    return this.deviceDetectorService.getDeviceInfo();
  }

  isSafari() {
    return this.deviceDetectorService.browser?.toLowerCase().indexOf("safari") >= 0;
  }

  isSafariMaior12() {
    return this.isSafari() && Number(this.deviceDetectorService.browser_version?.split(".")[0]) > 12;
  }

  isFirefox() {
    return this.deviceDetectorService.browser === "Firefox";
  }

  isMobile() {
    return !this.isTablet() && this.deviceDetectorService.isMobile();
  }

  isTablet() {
    const tabletPortrait = "(orientation: portrait) and (min-width: 670px)";
    const tabletLandscape = "(orientation: landscape) and (min-height: 670px)";
    return (
      this.validacaoTabletLocal() ||
      this.deviceDetectorService.isTablet() ||
      (this.deviceDetectorService.isMobile() &&
        (matchMedia(tabletPortrait).matches || matchMedia(tabletLandscape).matches))
    );
  }

  isDesktop() {
    return this.deviceDetectorService.isDesktop();
  }

  private validacaoTabletLocal() {
    return /sm-t385m|sm-p585m|sm-t515/i.test(navigator.userAgent);
  }
}
