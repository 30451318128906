import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TipoMessageEnum } from '../enums/tipo-message.enum';

export interface DataMessage<T> {
  tipo: TipoMessageEnum;
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private notificador = new Subject<DataMessage<any>>();

  constructor() { }

  listen<T>(tipoMessage: TipoMessageEnum): Observable<DataMessage<T>> {
    return this.notificador.pipe(
      filter(v => v.tipo === tipoMessage)
    );
  }

  speak<T>(tipo: TipoMessageEnum, data: T) {
    const dataMessage: DataMessage<T> = { tipo, data };
    this.notificador.next(dataMessage);
  }
}
