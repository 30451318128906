import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "primeng/api";
import { PopupEntrevistaDirecionadaComponent } from "src/app/pages/detalhe-acao-a-definir/popup-entrevista-direcionada/popup-entrevista-direcionada.component";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { AcaoDefinir } from "src/app/shared/models/acao-definir.model";
import { DetalheAcaoADefinir } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { RespostaEntrevistaDirecionada } from "src/app/shared/models/resposta-entrevista-direcionada";
import { TesteExecutadoAcaoDefinida } from "src/app/shared/models/teste-executado-acao-definida.model";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { RoleService } from "src/app/shared/services/role.service";

@Component({
  selector: "app-form-sugerir-acao-supervisao",
  templateUrl: "./form-sugerir-acao-supervisao.component.html",
  styleUrls: ["./form-sugerir-acao-supervisao.component.scss"],
})
export class FormSugerirAcaoSupervisaoComponent implements OnChanges, OnInit {
  @ViewChild("popupEntrevistaDirecionada")
  popupEntrevistaDirecionada: PopupEntrevistaDirecionadaComponent;
  @Output() saveSuccess = new EventEmitter();
  @Input() detalheAcaoADefinir: DetalheAcaoADefinir;
  acoesDefinidas: SelectItem[];
  acaoDefinidaTeste: AcaoDefinir;
  comentario: string;
  comentarioPreDefinido: string;
  loadingButtonSalvar: boolean;
  comentarioObrigatorio: boolean = false;
  exibirEntrevistaDirecionada: boolean = false;
  respostasDirecionadas: RespostaEntrevistaDirecionada[];
  isAcaoPreDefinida: boolean = false;

  constructor(
    private translateService: TranslateService,
    private roleService: RoleService,
    private dashboardService: DashboardService,
    private contratoService: ContratoService
  ) {}

  ngOnInit(): void {
    this.validarConfiguracoes();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.detalheAcaoADefinir.currentValue) {
      this.exibirEntrevistaDirecionada = this.detalheAcaoADefinir.cdEntrevistaDirecionada !== "NAO_EXIBIR";
      this.isAcaoPreDefinida = !!this.detalheAcaoADefinir?.preDefinicaoAcao?.idAcaoPreDefinida;
      this.comentarioPreDefinido = this.detalheAcaoADefinir?.preDefinicaoAcao?.dsJustificativaAcao || "";
      this.carregarAcoesDefinidas();
      this.preencherPreDefinicarAcao();
    }
  }

  validarConfiguracoes() {
    this.contratoService.getContrato().subscribe((config) => {
      this.comentarioObrigatorio = config.flComentarioObrigatorioDefinirAcao;
    });
  }

  preencherPreDefinicarAcao() {
    if (this.isAcaoPreDefinida) {
      this.acaoDefinidaTeste = this.detalheAcaoADefinir.acaoDefinir?.find(
        (acao) => acao.id == this.detalheAcaoADefinir.preDefinicaoAcao.idAcaoPreDefinida
      );
    }
  }

  carregarAcoesDefinidas(): void {
    this.acoesDefinidas = [];
    this.detalheAcaoADefinir.acaoDefinir.forEach((acao) => {
      this.acoesDefinidas.push({ label: acao.dsAcao, value: acao });
    });
    this.acoesDefinidas.sort((a, b) => a.label.trim().localeCompare(b.label.trim()));
  }

  definirAcao(): void {
    if (this.acaoDefinidaTeste != null) {
      this.loadingButtonSalvar = true;
      const testeExecutadoAcaoDefinida: TesteExecutadoAcaoDefinida = new TesteExecutadoAcaoDefinida();
      if (this.comentarioPreDefinido) {
        testeExecutadoAcaoDefinida.dsJustificativaAcao = `${this.comentarioPreDefinido ? "<br/><br/>" : ""}${
          this.comentario ? this.comentario : ""
        }`;
      } else {
        testeExecutadoAcaoDefinida.dsJustificativaAcao = this.comentario ? this.comentario : "";
      }
      testeExecutadoAcaoDefinida.idAcaoDefinida = this.acaoDefinidaTeste.id;
      testeExecutadoAcaoDefinida.respostasEntrevistaDirecionada = this.respostasDirecionadas;
      const shouldPreDefinirAcao =
        this.roleService.isRole(Permissao.SUPERVISOR_CHECKLIST) || this.roleService.isRole(Permissao.GESTOR);
      const endpoint = shouldPreDefinirAcao
        ? this.dashboardService.preDefinirAcao.bind(this.dashboardService)
        : this.dashboardService.definirAcao.bind(this.dashboardService);

      endpoint(this.detalheAcaoADefinir.idTesteExecutado, testeExecutadoAcaoDefinida).subscribe(
        (resp) => {
          this.loadingButtonSalvar = false;
          this.saveSuccess.emit();
        },
        (error) => {
          this.loadingButtonSalvar = false;
          throw error;
        }
      );
    }
  }

  setRespostas(respostas: RespostaEntrevistaDirecionada[]) {
    this.respostasDirecionadas = respostas;
  }

  showPopupEntrevista() {
    this.popupEntrevistaDirecionada.togglePopup();
  }
}
