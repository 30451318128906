import { ContratoService } from "./../../../../shared/api-services/contrato.service";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import moment from "moment";
import { ResponseGraficoIndicadorFadigaByUsuarioTo } from "src/app/shared/tos/response/response-grafico-inficador-fadiga-by-usuario";
import CORES_NIVEL_FADIGA from "../../indicadores-fadiga/cores-nivel-fadiga";
import { DashboardService } from "src/app/shared/services/dashboard.service";

import { SharedModule } from "src/app/shared/shared.module";
import { UsuarioDataService } from "src/app/shared/services/usuario-data.service";

@Component({
  selector: "app-grafico-indicador-fadiga",
  standalone: true,
  imports: [SharedModule],
  templateUrl: "./grafico-indicador-fadiga.component.html",
  styleUrls: ["./grafico-indicador-fadiga.component.scss"],
})
export class GraficoIndicadorFadigaComponent implements OnChanges, OnInit {
  @Input() username: string;
  @Input() idUsuario: number;
  chart: Chart;
  loadingDadosUsuario = true;
  dadosGraficoUsuario: ResponseGraficoIndicadorFadigaByUsuarioTo;
  nmCliente: string;
  flExibirFadiga: boolean;

  constructor(
    private translateService: TranslateService,
    private dashboardService: DashboardService,
    private usuarioDataService: UsuarioDataService,
    private contratoService: ContratoService
  ) {}

  ngOnInit(): void {
    this.nmCliente = this.usuarioDataService.getInstance()?.tokenParsed?.["cliente.nome"];
    this.contratoService.getContrato().subscribe((configuracoes) => {
      this.flExibirFadiga = configuracoes.flExibeFadiga;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idUsuario.currentValue) {
      const retry = setInterval(() => {
        if (this.flExibirFadiga) {
          this.buscarDadosUsuario(this.idUsuario);
          clearInterval(retry);
        } else if (this.flExibirFadiga == false) {
          clearInterval(retry);
        }
      });
    }
  }

  buscarDadosUsuario(idUsuario: number) {
    this.loadingDadosUsuario = true;
    this.dadosGraficoUsuario = null;
    this.dashboardService.getGraficoByUsuario(idUsuario).subscribe(
      (r) => {
        this.loadingDadosUsuario = false;
        this.dadosGraficoUsuario = r;
        this.configurarGrafico(r);
      },
      (err) => {
        this.loadingDadosUsuario = false;
        throw err;
      }
    );
  }

  configurarGrafico(dadosUsuario: ResponseGraficoIndicadorFadigaByUsuarioTo) {
    const getLabelFadica = (nivel) =>
      this.translateService.instant(CORES_NIVEL_FADIGA.getIndicadorByNivel(nivel).texto);
    const fadigaLabel = this.translateService.instant("indicadorFadiga.fadiga");
    const scoreData = dadosUsuario.dados.map((r) => ({
      x: moment(r.dtExecucao).toDate().getTime(),
      y: r.vlScoreFadiga,
      color: CORES_NIVEL_FADIGA.getIndicadorByNivel(r.vlScoreFadiga).cor,
    }));

    const ticks = [];
    for (let i = 0; i < 4; i++) {
      if (dadosUsuario.dados?.[0]?.dtExecucao) {
        ticks.push(
          moment(dadosUsuario.dados[0].dtExecucao)
            .add(7 * i, "d")
            .toDate()
            .getTime()
        );
      }
    }

    this.chart = new Chart({
      chart: {
        spacingRight: 50,
        height: 400,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 768,
            },
            chartOptions: {
              chart: {
                spacingRight: 0,
              },
            },
          },
        ],
      },
      title: {
        text: "",
      },
      tooltip: {
        formatter: function () {
          return `${moment(this.point.x).toDate().toLocaleString()} <br/>
          <b>${fadigaLabel} ${getLabelFadica(this.point.y)} </b>`;
        },
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              enabled: false,
            },
          },
        },
        scatter: {
          marker: {
            symbol: "circle",
          },
        },
      },
      xAxis: {
        tickInterval: 168 * 3600 * 1000,
        startOnTick: true,
        showFirstLabel: true,
        gridLineWidth: 1,
        labels: {
          enabled: true,
          formatter: function () {
            return moment(this.value).format("DD. MMM");
          },
          align: "left",
        },
      },
      yAxis: {
        min: -1,
        max: 6,
        tickInterval: 1,
        title: {
          text: "",
        },
        labels: {
          enabled: false,
        },
      },
      series: [
        {
          name: "Score",
          data: scoreData,
          type: "scatter",
          showInLegend: false,
        },
      ],
    });
  }
}
