<div>
  <div class="container-icone-voltar">
    <em style="cursor: pointer; color: #38ac46" class="material-icons" (click)="voltar()">arrow_back</em>
  </div>

  <app-loader [loading]="loaderDetalhe" />

  @if (!loaderDetalhe && detalheAcaoADefinir) {
  <app-detalhe-acao-a-definir [detalheAcaoADefinir]="detalheAcaoADefinir" (voltar)="voltar()" />
  <app-form-comentario-saude
    [idTesteExecutado]="detalheAcaoADefinir.idTesteExecutado"
    (cancel)="voltar()"
    (successTesteExecutado)="onSuggestActionSuccess()"
  />
  }
</div>
