import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectItem } from "primeng/api";
import { concatMap, map, switchMap, tap } from "rxjs/operators";
import { AcaoService } from "src/app/shared/api-services/acao.service";
import { AnaliseProntidaoService } from "src/app/shared/api-services/analise-prontidao.service";
import { RelatorioService } from "src/app/shared/api-services/relatorio.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { RequestTesteExecutadoComentarioMedicoTO } from "src/app/shared/tos/request/request-teste-executado-comentario-medico.to";
import { ResponseTesteExecutado } from "src/app/shared/tos/response/response-teste-executado";
import { saveAs as importedSaveAs } from "file-saver";
import {
  AVALIACAO_GERAL,
  AVALIACAO_MENTAL,
  AVALIACAO_MOTORA,
  AVALIACAO_PERCEPTIVA,
  QuestionarioAvalaicao,
} from "./questionarios-avaliacao";

@Component({
  selector: "app-form-comentario-saude",
  templateUrl: "./form-comentario-saude.component.html",
  styleUrls: ["./form-comentario-saude.component.scss"],
})
export class FormComentarioSaudeComponent implements OnChanges {
  @Output() cancel = new EventEmitter();
  @Output() successTesteExecutado = new EventEmitter();
  @Input() idTesteExecutado: number;

  comentariosSupervisor: string[];
  loadingTeste: boolean;
  loading: boolean;
  loadButtonRelatorioTeste: boolean;
  acoesDefinidas: SelectItem[];
  testeExecutado: ResponseTesteExecutado;
  requestTesteExecutadoComentarioMedicoTO: RequestTesteExecutadoComentarioMedicoTO;
  avaliacaoPerceptiva: QuestionarioAvalaicao[] = AVALIACAO_PERCEPTIVA;
  avaliacaoMotora: QuestionarioAvalaicao[] = AVALIACAO_MOTORA;
  avaliacaoMental: QuestionarioAvalaicao[] = AVALIACAO_MENTAL;
  avaliacaoGeral: QuestionarioAvalaicao[] = AVALIACAO_GERAL;

  constructor(
    private relatorioService: RelatorioService,
    private render2: Renderer2,
    private analiseProntidaoService: AnaliseProntidaoService,
    private translateService: TranslateService,
    private acaoService: AcaoService,
    private toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idTesteExecutado.currentValue) {
      this.requestTesteExecutadoComentarioMedicoTO = new RequestTesteExecutadoComentarioMedicoTO();
      this.buscarTesteExecutado(this.idTesteExecutado);
    }
  }

  async buscarTesteExecutado(id: number) {
    this.loadingTeste = true;
    this.analiseProntidaoService
      .getTesteExecutado(id)
      .pipe(
        switchMap((teste) => {
          this.testeExecutado = teste;
          this.comentariosSupervisor = teste.dsJustificativaAcao?.length
            ? teste.dsJustificativaAcao.split("-- <br/><br/>")
            : [""];
          this.requestTesteExecutadoComentarioMedicoTO.fromTesteExecutado(teste);
          return this.carregarAcoesDefinidasByHierarquia(teste.idHierarquia);
        })
      )
      .subscribe(
        (responseAcoes) => {
          if (!this.requestTesteExecutadoComentarioMedicoTO.idAcaoDefinida) {
            const acaoDefinida = responseAcoes.find(
              (acao) => acao.label === this.testeExecutado?.dsAcaoDefinida
            );
            this.requestTesteExecutadoComentarioMedicoTO.idAcaoDefinida = acaoDefinida?.value;
          }
          this.loadingTeste = false;
        },
        (err) => {
          this.loadingTeste = false;
          throw err;
        }
      );
  }

  carregarAcoesDefinidasByHierarquia(idHierarquia: number) {
    this.acoesDefinidas = [];
    this.acoesDefinidas.push({
      label: this.translateService.instant("dashboard.nenhumaAcaoDefinida"),
      value: null,
    });
    return this.acaoService.getAcoesByIdHierarquia(idHierarquia).pipe(
      map((resposta) => {
        resposta.forEach((acao) => {
          this.acoesDefinidas.push({ label: acao.dsAcao, value: acao.id });
        });
        return this.acoesDefinidas;
      })
    );
  }

  onSubmit(): void {
    this.loading = true;
    this.analiseProntidaoService
      .createTesteExecutadoLaudoMedico(this.requestTesteExecutadoComentarioMedicoTO.toApi())
      .subscribe(
        (response) => {
          this.loading = false;
          this.successTesteExecutado.emit();
        },
        (error) => {
          this.loading = false;
          throw error;
        }
      );
  }

  gerarRelatorioTeste() {
    this.loadButtonRelatorioTeste = true;
    this.analiseProntidaoService.getTesteExecutado(this.testeExecutado.idTesteExecutado).subscribe(
      (resposta) => {
        this.relatorioService.downloadUltimos30Testes(resposta.idUsuario).subscribe(
          (resp) => {
            this.relatorioService.downloadRelatorioTeste(resp.dsUrlArquivo).subscribe(
              (file) => {
                importedSaveAs(
                  new Blob([file], { type: "application/zip" }),
                  "relatório-teste" + new Date().getTime()
                );
                this.loadButtonRelatorioTeste = false;
              },
              (error) => {
                this.loadButtonRelatorioTeste = false;
                throw error;
              }
            );
          },
          (error) => {
            this.loadButtonRelatorioTeste = false;
            throw error;
          }
        );
      },
      (error) => {
        this.loadButtonRelatorioTeste = false;
        throw error;
      }
    );
  }
}
