import { Pipe, PipeTransform } from '@angular/core';
import { AreaDestino } from 'src/app/shared/enums/cd-area-destino';
import { ResponsePendenteAcaoDefinidaTO } from 'src/app/shared/models/response-pendente-acao-definida.to';

@Pipe({
  name: 'filtrarAreaDestino'
})
export class FiltrarAreaDestinoPipe implements PipeTransform {

  transform(value: ResponsePendenteAcaoDefinidaTO[], areaDestino: AreaDestino): ResponsePendenteAcaoDefinidaTO[] {
    if (!value || !areaDestino) return value;
    return value.filter(acao => acao.cdAreaDestino === areaDestino)
  }

}
